<template>
  <section class="ekifa-blog-page-area section_80">
    <b-container>
      <b-row>
        <b-col md="12" lg="12">
          <b-card-group deck>
            <b-card no-body header="Government agencies">
              <b-list-group flush>
                <b-list-group-item href="#">Link 1 - Quis magna Lorem anim amet ipsum </b-list-group-item>
                <b-list-group-item href="#">Link 2 - Quis magna Lorem anim amet ipsum </b-list-group-item>
                <b-list-group-item href="#">Link 3 - Quis magna Lorem anim amet ipsum </b-list-group-item>
              </b-list-group>

              <!--            <p class="card-text mt-2">-->
              <!--              Quis magna Lorem anim amet ipsum do mollit sit cillum voluptate ex nulla tempor. Laborum-->
              <!--              consequat non elit enim exercitation cillum aliqua consequat id aliqua. Esse ex consectetur-->
              <!--              mollit voluptate est in duis laboris ad sit ipsum anim Lorem.-->
              <!--            </p>-->
            </b-card>

            <b-card no-body header="Non-profit organizations">
              <b-list-group flush>
                <b-list-group-item href="#">Link 1 - Quis magna Lorem anim amet ipsum </b-list-group-item>
                <b-list-group-item href="#">Link 2 - Quis magna Lorem anim amet ipsum </b-list-group-item>
                <b-list-group-item href="#">Link 3 - Quis magna Lorem anim amet ipsum </b-list-group-item>
              </b-list-group>

              <!--            <b-card-body>-->
              <!--              Quis magna Lorem anim amet ipsum do mollit sit cillum voluptate ex nulla tempor. Laborum-->
              <!--              consequat non elit enim exercitation cillum aliqua consequat id aliqua. Esse ex consectetur-->
              <!--              mollit voluptate est in duis laboris ad sit ipsum anim Lorem.-->
              <!--            </b-card-body>-->
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
const guides = require('../json/Resources/legal-guides.json')
export default {
  name: "ExternalLinksArea",
  components: {},
  data() {
    return {
      guides : guides
    }
  }
}
</script>

<style scoped>
.card{
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: .25rem;
}

  .card-deck .card {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }

.card-header {
  padding: .75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0,0,0,.03);
  border-bottom: 1px solid rgba(0,0,0,.125);
  /*background-color: #ec3838;*/
  background-color: #dbdbdb;
  font-size: large;
  font-weight: bold;
}

.card-header:first-child {
  border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}
</style>