<template>
<div>
  <!-- Breadcrumb Component -->
  <breadcrumb-area :breadcrumb="breadcrumb"/>
  <!-- FAQ Area Component -->
  <external-links-area/>
</div>
</template>

<script>
import BreadcrumbArea from "../../components/BreadcrumbArea";
import ExternalLinksArea from "../../components/ExternalLinksArea";
export default {
  name: "ExternalLinks",
  components: {ExternalLinksArea, BreadcrumbArea},
  data() {
    return {
      breadcrumb: {
        page: "Links",
        pageTitle: "Useful Links"
      }
    }
  }
}
</script>

<style scoped>

</style>